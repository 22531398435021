<!-- page dashboard -->
<template>
  <Dashboard :appData="appDatas" >
    <TopAppBar slot="header" title="Dashboard" />
  </Dashboard>
</template>

<script>
import { menuIconParams } from "@/common/baseData";
import { mapState } from "vuex";
import { Ajax } from "@/common";
import { Auth } from "@/common";
import { lightFormat } from "date-fns";
export default {
  components: {},
  data() {
    return {
		loading:true,
      menuIconParams: menuIconParams,
      appDatas: [],
      appData: [
        {
          label: "Admin Console",
          src: require("@/assets/images/menu/adminconsole.svg"),
          path: "/adminConsole",
          multiple: false,
        },
        {
          label: "Companies",
          src: require("@/assets/images/menu/companies.svg"),
          path: "/companies",
          multiple: false,
        },
        {
          label: "Parents",
          src: require("@/assets/images/menu/parents.svg"),
          path: "/parents",
          multiple: false,
        },
        {
          label: "Staff",
          src: require("@/assets/images/menu/staff.svg"),
          path: "/staff",
          multiple: false,
        },
        {
          label: "Students",
          src: require("@/assets/images/menu/students.svg"),
          path: "/students",
          multiple: false,
        },
        {
          label: "Classes",
          src: require("@/assets/images/menu/classes.svg"),
          path: "/classes",
          multiple: true,
        },
        {
          label: "People",
          src: require("@/assets/images/menu/people.svg"),
          path: "/people",
          multiple: true,
        },
        {
          label: "Attendance",
          src: require("@/assets/images/menu/attendance.svg"),
          path: "/attendance",
          multiple: true,
        },
        {
          label: "Commbook",
          src: require("@/assets/images/menu/commbook.svg"),
          path: "/commbook",
          multiple: true,
        },
        {
          label: "Announcements",
          src: require("@/assets/images/menu/announcement.svg"),
          path: "/announcement",
          multiple: true,
        },
        {
          label: "Moments",
          src: require("@/assets/images/menu/moments.svg"),
          path: "/moments",
          multiple: true,
        },
        {
          label: "Journal",
          src: require("@/assets/images/menu/journal.svg"),
          path: "/journal",
          multiple: true,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  methods: {
    getLocation() {
      let rolePermission = this.user.rolePermission;
      let userType = this.user.userType;
      let isLocation = false;
	  let linshi = []
      linshi = this.appData.filter((item) =>
        rolePermission.some((item1) => item1 === item.label)
      );

      console.log(linshi)
		
      isLocation = linshi.some((item) => item.multiple);
      Ajax.get("/usermanage/location/selectLocationByUserId", {
        userId: this.user.userId,
      }).then((res) => {
        if (res.data.length > 1 && isLocation) {
          linshi = linshi.filter((item) => {
            return !item.multiple;
          });

          linshi.push({
            label: "Locations",
            src: require("@/assets/images/menu/locations.svg"),
            path: "/locations",
            multiple: false,
          });
		this.appDatas = linshi
		this.loading=false
        } else {
			this.appDatas = linshi
				this.loading=false
          if (res.data.length == 0) {
            this.$store.commit("SET_LOCATIONID", "");
          } else {
            this.$store.commit("SET_LOCATIONID", res.data[0].id);
			this.$store.commit("SET_SCHOOLNAME", res.data[0].name);
          }
        }
      });
    },
  },
  created() {
    // this.getWorkType();
    // this.getJobTitle();
    // this.getRole();
    this.getLocation();
    // this.$store.commit("SET_ROLEPERMISSION", [
    //   "Announcement",
    //   "Commbook",
    //   "Companies",
    //   "Parents",
    //   "Staff",
    // ]);

    // 放开dashboard权限控制
    // let rolePermission = this.user.rolePermission;
    // console.log(rolePermission);
    // this.appData = this.appData.filter((item) => {
    //   return rolePermission.indexOf(item.label) != -1;
    // });
  },
};
</script>
<style lang='less' scoped>
</style>